<template>
	<div class="User">
		
		<div class="Top">
			<div class="Left">
				公众号消息推送
			</div>
			<div class="Right">
				
			</div>
		</div>
		


	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination} from 'element-ui'
	export default {
	  name: 'GZHList',
	  props: {
	  },
	  data() {
	      return {
			  Search:{
				  Id:'',
				  Identity:'',
				  OaNo:'',
				  Mobile:'',
				  Account:'',
				  Name:'',
			  },
			  UserList:[],
			  Total:0,
			  Page:1,
			  PageSize:15,
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination
	  },
	  created() {
	  	this.GetGzhMessageList()
	  },
	  methods:{
		  GetGzhMessageList(){
		  		let data = {
		  			Service:'Base',
		  			Class: 'Message',
		  			Action: 'GzhList',
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
					
		  		})
		  		.catch(function (response) {
					this.$message('网络请求错误')
		  		})
		  }
	  }
	}
</script>

<style scoped>
.User{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.User .Top{
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.05);
	padding-bottom: 20px;
}
.User .Top .Left{
	
}
.User .Top .Right{
	flex: 1;
	display: flex;
	color: #999999;
	justify-content: flex-end;
}
.User .Top .Right span{
	margin-left: 10px;
}
.User .Top .Right label{
	display: block;
	font-size: 0.9rem;
	color: rgba(0,0,0,0.4);
}
.User .List{
}
.C1,.C2,.C3,.C4,.C5{
	width: 150px;
}
.C1{
	padding-left: 10px;
}
.C6{
	flex: 1;
	text-align: right;
	padding-right: 10px;
}
.User .List .Title{
	display: flex;
	margin-bottom: 20px;
	color: rgba(0,0,0,0.3);
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid rgba(0,0,0,0.04);
	font-size: 0.9rem;
}
.User .List .One{
	border-bottom: 1px solid rgba(0,0,0,0.04);
	padding: 10px 0px;
}
.User .List .One:hover{
	background-color: rgba(228,0,0,0.1);
}
.User .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
</style>
